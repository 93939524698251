import React from 'react';
import { graphql } from 'gatsby';
import { Article, Content, Header, Layout, Pagination, SectionTitle } from '../components';
import { Helmet } from 'react-helmet';
import SiteConfig from '../../config/SiteConfig';
import Data from '../models/Data';
import { HeaderItem } from '../components/Header';

interface Props {
  data: Data;
  pageContext: {
    currentPage: number;
    totalPages: number;
  };
}

export default class WritingPage extends React.Component<Props> {
  public render() {
    const { currentPage, totalPages } = this.props.pageContext;

    const { data } = this.props;
    const { edges } = data.allMarkdownRemark;

    return (
      <Layout>
        <Helmet title={`Blog | ${SiteConfig.siteTitle}`}/>
        <Header linkToHomepage={true} selected={HeaderItem.Writing}>
          <SectionTitle uppercase={true}>Writing</SectionTitle>
          <div>Occasionally I write something more substantial than a tweet. This is a collection of such writing:</div>
        </Header>
        <Content>
          {edges.map(post => (
            <Article
              title={post.node.frontmatter.title}
              date={post.node.frontmatter.date}
              excerpt={post.node.excerpt}
              timeToRead={post.node.timeToRead}
              path={post.node.fields.path}
              category={post.node.frontmatter.category}
              key={post.node.fields.slug}
            />
          ))}
          <Pagination currentPage={currentPage} totalPages={totalPages} url={'blog'}/>
        </Content>
      </Layout>
    );
  }
}
export const WritingPageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {category: {ne: "photos"}}}
     ) {
      totalCount
      edges {
        node {
          fields {
            slug
            path
          }
          frontmatter {
            title
            subtitle
            date(formatString: "D MMM YYYY")
            year: date(formatString: "YYYY")
            month: date(formatString: "MM")
            day: date(formatString: "DD")
            category
            slug
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`;
